// SelectOption.js
import React from "react";

const SelectOption = ({ name, value, onChange, options, placeholder, required }) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className=""
      required={required}
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </select>
  );
};

export default SelectOption;
