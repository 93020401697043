export const educationLevelOptions = [
  "10th",
  "11th",
  "12th",
  "ITI",
  "Diploma",
  "Degree",
  "Master Degree",
  "Engineering",
  "Biotechnology",
  "Bachelor of Architecture",
  "Medical",
  "Para medical",
  "Bioinformatics",
  "Genetics",
  "Microbiology",
  "Forensic Sciences",
  "Environmental Science",
  "B. Ed",
  "M. Ed",
  "B. Sc Speach & Hearing",
  "M. Sc Speach & Hearing",
  "LLB",
  "M Pharm",
  "MBA",
  "MTech",
  "Hotel management",
  "PhD",
  "Other"
];

export const branchOptions = {
  "10th": ["State", "CBSE", "ISCE"],
  "11th": ["State", "CBSE", "ISCE"],
  "12th": ["State", "CBSE", "ISCE"],
  ITI: [],
  Diploma: [],
  Degree: ["B. Sc", "B. A", "B. Com", "B. C. A", "B. A. Journalism"],
  "Master Degree": ["M. Sc", "M. A", "M. Com", "M. A. Journalism"],
  Engineering: [],
  Biotechnology: [],
  "Bachelor of Architecture": [],
  Medical: ["MBBS", "BAMS", "BHMS", "BNYS", "BUMS", "Vetarnary", "BDS"],
  "Para medical": [
    "Lab technician",
    "X ray technician",
    "Nursing",
    "Physiotherapy",
  ],
  Bioinformatics: [],
  Genetics: [],
  Microbiology: [],
  "Forensic Sciences": [],
  "Environmental Science": [],
  "B. Ed": [],
  "M. Ed": [],
  "B. Sc Speach & Hearing": [],
  "M. Sc Speach & Hearing": [],
  LLB: [],
  "M Pharm": [],
  MBA: [],
  MTech: [],
  "Hotel management": [],
  PhD: [],
  Other:[],
};

export const yearOptions = {
  "10th": [],
  "11th": [],
  "12th": [],
  ITI: ["1st", "2nd", "3rd"],
  Diploma: ["1st", "2nd", "3rd"],
  Degree: ["1st", "2nd", "3rd"],
  "Master Degree": ["1st", "2nd"],
  Engineering: ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"],
  Biotechnology: ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"],
  "Bachelor of Architecture": [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
  ],
  Medical: ["1st", "2nd", "3rd", "4th", "5th"],
  "Para medical": ["1st", "2nd"],
  Bioinformatics: ["1st", "2nd", "3rd"],
  Genetics: ["1st", "2nd", "3rd"],
  Microbiology: ["1st", "2nd", "3rd"],
  "Forensic Sciences": ["1st", "2nd", "3rd"],
  "Environmental Science": ["1st", "2nd", "3rd"],
  "B. Ed": ["1st", "2nd", "3rd"],
  "M. Ed": ["1st", "2nd", "3rd"],
  "B. Sc Speach & Hearing": ["1st", "2nd", "3rd"],
  "M. Sc Speach & Hearing": ["1st", "2nd", "3rd"],
  LLB: ["1st", "2nd", "3rd"],
  "M Pharm": ["1st", "2nd", "3rd", "4th"],
  MBA: ["1st", "2nd"],
  MTech:["1st", "2nd"],
  "Hotel management": ["1st", "2nd", "3rd", "4th"],
  PhD: ["1st", "2nd", "3rd", "4th", "5th", "6th"],
  Other:[],
};

export const genderOptions = ["Male", "Female"];
