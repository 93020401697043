import React from "react";
import ScrollingNotification from '../home/ScrollingNotification';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import useHandleLinkClick from '../../useHandleLinkClick';
const BannerTwo = () => {
  const handleLinkClick = useHandleLinkClick();  
  return (
    <section className="banner">
      <div className="banner-image">
        <ScrollingNotification />
        <div className="banner-carousel owl-theme owl-carousel"  style={{ overflow: "hidden" }}>
          <div className="slide-item" 
            style={{ 
              backgroundImage: "url(assets/images/banner/banner-1.jpg)", 
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height : "100%",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="banner-slide">
                    <div className="banner-content">
                      <div className="banner-content-wrapper">
                        <div className="banner-content-wrapper-inner">
                          <h4>Empowering Dreams</h4>
                          <h2>Empower through Education, Unlock Potential!</h2>
                          <p>Opportunity Awaits: Apply for Scholarships Now!</p>
                          <div className="btn-group">
                            <div className="header-link-btn">
                              <ScrollLink
                                to="userinfo-section"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                                onClick={handleLinkClick("userinfo-section")}
                                className="btn-1 btn-alt"
                              > 
                                Register <span />
                              </ScrollLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="banner-right-content">
                    {/* <div className="banner-right-image">
                      <img
                        src="assets/images/banner/banner-1.jpg"
                        alt="banner-two-image"
                      />
                    </div> */}
                    {/* <div className="border-image">
                      <img
                        src="assets/images/shape/border-image.png"
                        alt="border"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item" 
            style={{ 
              backgroundImage: "url(assets/images/banner/banner-2.jpg)", 
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="banner-slide">
                    <div className="banner-content">
                      <div className="banner-content-wrapper">
                        <div className="banner-content-wrapper-inner">
                          <h4>Building Futures</h4>
                          <h2>Scholarships: Fueling Ambitions</h2>
                          <p>Transform Your Future: Apply Today!</p>
                          <div className="btn-group">
                            <div className="header-link-btn">
                              <ScrollLink
                                to="userinfo-section"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                                onClick={handleLinkClick("userinfo-section")}
                                className="btn-1 btn-alt"
                              > 
                                Register <span />
                              </ScrollLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="banner-right-content">
                    {/* <div className="banner-right-image">
                      <img
                        src="assets/images/banner/banner-right-image-02.png"
                        alt="banner-two-image"
                      />
                    </div> */}
                    {/* <div className="border-image">
                      <img
                        src="assets/images/shape/border-image.png"
                        alt="border"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item" 
            style={{ 
              backgroundImage: "url(assets/images/banner/banner-3.jpg)", 
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="banner-slide">
                    <div className="banner-content">
                      <div className="banner-content-wrapper">
                        <div className="banner-content-wrapper-inner">
                          <h4>Don't miss out</h4>
                          <h2>Cash Reward scholarship worth 5K to 1 Lakh</h2>
                          <p>Don't Miss Out: Apply Now!</p>
                          <div className="btn-group">
                            <div className="header-link-btn">
                              <ScrollLink
                                to="userinfo-section"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                                onClick={handleLinkClick("userinfo-section")}
                                className="btn-1 btn-alt"
                              > 
                                Register <span />
                              </ScrollLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="banner-right-content">
                    {/* <div className="banner-right-image">
                      <img
                        src="assets/images/banner/banner-right-image-03.png"
                        alt="banner-two-image"
                      />
                    </div>
                    <div className="border-image">
                      <img
                        src="assets/images/shape/border-image.png"
                        alt="border"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
