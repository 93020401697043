import React from "react";
import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <section className="about home-two-about" id="about-section">
      {/* <div className="home-two-about-shape" >
        <img src="assets/images/shape/home-two-about-shape.png" alt="shape" />
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="about-left-container">
              {/* <div
                className="home-two-about-image-top paroller"
                style={{
                  transform: "unset",
                  transition: "transform 0.6s cubic-bezier(0, 0, 0, 1) 0s",
                  willChange: "transform",
                }}
              >
                <img
                  src="assets/images/gallery/home-two-about-top.png"
                  alt="image"
                />
              </div> */}
              <div className="about-image-1 wow fadeInUp animated">
                <img
                  src="assets/images/about.jpg"
                  alt="image"
                  style={{ 
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    backgroundSize: "cover",
                  }}
                />
              </div>
              {/* <div
                className="home-two-about-image-bottom paroller"
                style={{
                  transform: "unset",
                  transition: "transform 0.6s cubic-bezier(0, 0, 0, 1) 0s",
                  willChange: "transform",
                }}
              >
                <img
                  src="assets/images/gallery/home-two-about-bottom.png"
                  alt="image"
                />
              </div> */}
            </div>
          </div>
          <div className="col-xl-6">
            <div className="home-two-about-wrapper">
              <div className="about-right-container">
                <div className="common-title">
                  <h5>Get to know about Adhyayan scholarship</h5>
                  {/* <h3>We're non-profit charity &amp; NGO organization</h3> */}
                </div>
                <h6 className="p-justify">
                According to Malcolm X <strong>"Education is the passport to the future, for tomorrow belongs to those who prepare for it today"</strong>. 
                <br/><br/>
                Education and Money are two sides of the coin on everybody life and both are important acepts having their own influence the quality of life of individuals and societies. 
                <br/><br/>
                There is a strong bond between money and education, as both can be seen as forms of human strength. Human capital mainly skills, knowledge and experience of the person transformed into economic value by the support of money. With the help of money in human capital, through education or training, can increase the productivity, income of individuals and their family with the economic growth of societies.
                </h6>
                <div className="faq-accordion about-accordion">
                  <style>
                    {`
                      
                      @media only screen and (max-width: 740px) {
                        .about-button::after {
                          top: 21px;
                        }
                        .about-button:not(.collapsed)::after {
                          top: 21px;
                        }
                      }
                      .about-accordion {
                        margin-top: 20px;
                      }
                        @media only screen and (max-width: 1024px) {
                          .about-button::after {
                            top: 21px;
                          }
                        }
                    `}
                  </style>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="about-button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Read More
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <h6 className="p-justify">
                            Keeping in mind above socio imbalance of the influence of money on education, thoughts of group of the visionary people's in Garike Trust(R) started as sponsoring scholarships to small group of students in 2018 in Mandya only. Instead of every year by expanding this program to limited students in limted area, this program have to reach all students those having desire of education to fullfill the dream of their life. 
                          </h6>
                          <h6 className="p-justify">
                            From this year on wards, this scholarship program re-constituted as <strong>"Adhyayan Scholarship"</strong> program to reach all students through online mode with digital enrolment and accessing examination is made easy through online on any situation.
                          </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="header-link-btn">
                  <a href="blog-details.html" target="_blank" className="btn-1">
                    Discover more
                    <span />
                  </a>
                </div> */}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
