import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import MobileMenu from "../../components/MobileMenu";
import Footer from "../../components/Footer";
import Scroller from "../../components/Scroller";
const NoPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/script.js";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <Header />
      <MobileMenu />
      <section
        className="common-banner"
        style={{
          backgroundImage: "url('assets/images/banner/common-banner-bg.png')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common-banner-title">
                <h3>404 Error</h3>
                <Link to="/">Home </Link>/<span> 404 Error</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="error">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="error-content">
                <h2>404</h2>
                <h4>Oops, page not found!</h4>
                <p>
                  We're sorry, but the page you are looking for does not exist. It seems like
                  you've encountered a broken link or entered a URL that doesn't lead
                  anywhere.
                </p>
                <Link to="/" className="btn-1">
                  Back to home <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scroller />
    </div>
  );
};

export default NoPage;
