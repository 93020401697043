// UserInfo.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import "jquery-validation";
import Swal from "sweetalert2";

import FormField from "../../components/register/FormField";
import SelectOption from "../../components/register/SelectOption";
import {
  educationLevelOptions,
  branchOptions,
  yearOptions,
  genderOptions,
} from "./educationOptions.js";
import { TermsPreview } from "./TermsPreview.js";
import { PrivacyPolicyPreview } from "./PrivacyPolicyPreview.js";
import FormDataPreview from "./FormDataPreview";
import "./Form.css";
import "./Modal.css";
import PaymentModal from "../../pages/payment/PaymentModal";
import Preloader from "../../components/Preloader";

const UserInfo = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    state: "",
    district: "",
    taluk: "",
    states: [],
    districts: [],
    taluks: [],
    pin: "",
    phoneNumber: "",
    email: "",
    schoolName: "",
    schoolPlace: "",
    schoolContact: "",
    schoolCode: "",
    termsAccepted: false,
    educationLevel: "",
    branch: "",
    year: "",
    study:"",
    specialization: "",
  });
  const captchaSitekey = "6LfEcHQpAAAAANtrim9TrTzmuUkl8f44CStS_1E2";// works for the localhost, vibhaait.in and myadhyayan.in
  const [showPreview, setShowPreview] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  // const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const location = useLocation();
  const baseURL = 'https://adhyayan-admin.vibhaait.in/public/api';
  // const baseURL = 'http://127.0.0.1:8000/api';
  useEffect(() => {
    // Fetch states data from the backend API
    axios
      .get(`${baseURL}/states`)
      .then((response) => {
        setFormData((prevState) => ({
          ...prevState,
          states: response.data,
        }));
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });

    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");
    if (message) {
      const text =
        message === "SUCCESS"
        ? `<span className="success-message">
                🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
              </span>`
        : message === "ERROR"
        ? `<span className="error-message">
                ❌ Something went wrong. Please contact us for further information.
              </span>`
        : `<span className="error-message">
                ❌ Oops! Payment Failed. Please try again.
              </span>`;

      Swal.fire({
        title: "<strong>" + message + "</strong>",
        icon: message === "SUCCESS" ? "success" : "error",
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: `
          <i class="fa fa-thumbs-up"></i> Great!
        `,
        confirmButtonAriaLabel: "Thumbs up, great!",
        // cancelButtonText: `
        //   <i class="fa fa-thumbs-down"></i>
        // `,
        // cancelButtonAriaLabel: "Thumbs down",
      }).then(() => {
        // Redirect to the register page
        window.location.replace("/");
      });
    }

    $("#userInfoForm").validate({
      rules: {
        name: "required",
        pin: {
          required: true,
          minlength: 6,
          maxlength: 6,
          digits: true,
        },
        phoneNumber: {
          required: true,
          minlength: 10,
          maxlength: 10,
          digits: true,
        },
        schoolContact: {
          required: true,
          minlength: 10,
          maxlength: 10,
          digits: true,
        },
      },

      messages: {
        name: "Please enter your name",
        pin: {
          required: "* Please enter your PIN",
          minlength: $.validator.format("* PIN must be {0} digits long"),
          maxlength: $.validator.format("* PIN must be {0} digits long"),
          digits: "* Please enter only digits",
        },
        phoneNumber: {
          required: "* Please enter your phone number",
          minlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          digits: "* Please enter only digits",
        },
        schoolContact: {
          required: "* Please enter school contact number",
          minlength: $.validator.format(
            "* School contact must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* School contact must be {0} digits long"
          ),
          digits: "* Please enter only digits",
        },
      },
      errorPlacement: function (error, element) {
        if (element.attr("type") === "checkbox") {
          error.insertAfter(element.closest(".terms-checkbox"));
        } else {
          error.insertAfter(element);
        }
      },
      submitHandler: function (form, event) {
        event.preventDefault();
        // Your form submission logic here
        // For example, call handleSubmit() function
        setShowPreview(true);
      },
    });
  }, []);

  const fetchDistricts = async (stateId) => {
    try {
      if (!stateId) {
        // If stateId is empty, clear the districts dropdown
        setFormData((prevData) => ({
          ...prevData,
          districts: [],
          taluks: [], // Clear the taluks dropdown
        }));
        return; // Return early, no need to make the API call
      }

      const response = await axios.get(
        `${baseURL}/states/${stateId}/districts`
      );
      setFormData((prevData) => ({
        ...prevData,
        districts: response.data,
      }));
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        districts: [],
        taluks: [], // Clear the taluks dropdown
      }));
      console.error("Error fetching districts:", error);
    }
  };

  const fetchTaluks = async (districtId) => {
    try {
      if (!districtId) {
        // If districtId is empty, clear the taluks dropdown
        setFormData((prevData) => ({
          ...prevData,
          taluks: [],
        }));
        return; // Return early, no need to make the API call
      }

      const response = await axios.get(
        `${baseURL}/districts/${districtId}/taluks`
      );
      setFormData((prevData) => ({
        ...prevData,
        taluks: response.data,
      }));
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        taluks: [],
      }));
      console.error("Error fetching taluks:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      // If the changed field is educationLevel, reset the related fields
      if (name === "educationLevel") {
        return {
          ...prevData,
          [name]: value,
          branch: "",
          year: "",
          study: "",
          specialization: "",
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
    if (name === "state") {
      fetchDistricts(value);
      fetchTaluks(value);
    } else if (name === "district") {
      fetchTaluks(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPreview(true);
    // Handle form submission logic here
    // console.log(formData);
  };

  const termsHandler = (e) => {
    setShowterms(true);
  };
  const privacyHandler = () => {
    setPrivacyPolicy(true);
  }

  const handleClose = () => {
    setShowPreview(false);
    setShowterms(false);
    setCaptchaVerified(false);
    setPrivacyPolicy(false);
  };

  // const handleTermClose = () => {
  //   setShowterms(false);
  // };

  const handleCaptchaChange = (value) => {
    // Check if the ReCAPTCHA has been successfully completed
    setCaptchaVerified(value !== null);
  };
  const handlePayNow = () => {
    if (captchaVerified) {
      // Perform "PAY NOW" action if ReCAPTCHA is successfully completed
      // setIsPaymentModalOpen(true);
      
      console.log("PAY NOW action triggered");
      // setIsLoading(true);
      // performPaymentAction();
      Swal.fire({
        title: "<strong>NOTE!!!</strong>",
        icon: "info",
        html: "If you stop the payment process midway, you'll need to wait two hours and then re-register with the same details. Thank you for your patience!",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Continue"
      }).then(() => {
        setIsLoading(true);
        // performPaymentAction();         // Comment this after enabling the razorpay 
        performPaymentActionLive();  // Uncomment this after enabling the razorpay 
      });
      
    } else {
      alert("Please complete the ReCAPTCHA before proceeding.");
    }
  };

  const performPaymentAction = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/payment/user-info`,
        formData
      );
      // Redirect user to paymentUrl or display QR code for payment
      // const paymentUrl = response.data.redirect;
      // window.location.href = paymentUrl;
      // window.location.reload();
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      if (response.data.success) {
        console.log(response.data.success);
        const resMessage = "SUCCESS";
        const text =
        resMessage === "SUCCESS"
          ? `<span className="success-message">
                  🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
                </span>`
          : resMessage === "ERROR"
          ? `<span className="error-message">
                  ❌ Something went wrong. Please contact us for further information.
                </span>`
          : `<span className="error-message">
                  ❌ Oops! Payment Failed. Please try again.
                </span>`;
  
        Swal.fire({
          title: "<strong>" + resMessage + "</strong>",
          icon: resMessage === "SUCCESS" ? "success" : "error",
          html: `${text}`,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: `
            <i class="fa fa-thumbs-up"></i> Great!
          `,
          confirmButtonAriaLabel: "Thumbs up, great!",
          // cancelButtonText: `
          //   <i class="fa fa-thumbs-down"></i>
          // `,
          // cancelButtonAriaLabel: "Thumbs down",
        }).then(() => {
          // Redirect to the register page
          window.location.replace("/");
        });
      }
    } catch (error) {
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      const text =
        '<span className="error-message">❌ Please try again.</span>';

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorHtml = '<ul >';
        let index = 1;
        for (let fieldName in errors) {
            errorHtml += `<li style="text-align: left;">${index}. ${errors[fieldName][0]}</li>`;
            index++;
        }
        errorHtml += '</ul>';
        Swal.fire({
            title: "<strong>Error initiating payment</strong>",
            icon: "error",
            html: errorHtml,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      } else {
        Swal.fire({
            title: "<strong>Unexpected Error</strong>",
            icon: "error",
            html: "An unexpected error occurred. Please try again later.",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      }
    }
  };

  const performPaymentActionLive = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/payment/initiate`,
        formData
      );
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      
      if (response.data.success) {
        const orderId = response.data.razorpayData.order_id;
        const options = {
          ...response.data.razorpayData,
          handler: async function (paymentResponse) {
            console.log(paymentResponse);
            setIsLoading(true);
            try {
              // Make an API call to your backend to verify and store the payment
              const verificationResponse = await axios.post(
                `${baseURL}/payment/verify`,
                paymentResponse
              );
              
              if (verificationResponse.data.success) {
                setIsLoading(false);
                const text = `<span className="success-message">
                            🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
                            </span>`;
                Swal.fire({
                  title: "<strong>SUCCESS</strong>",
                  icon: "success",
                  html: `${text}`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: `
                    <i class="fa fa-thumbs-up"></i> Great!
                  `,
                  confirmButtonAriaLabel: "Thumbs up, great!",
                  // cancelButtonText: `
                  //   <i class="fa fa-thumbs-down"></i>
                  // `,
                  // cancelButtonAriaLabel: "Thumbs down",
                }).then(() => {
                  // Reload the website.
                  window.location.replace("/");
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment Verification Failed</strong>",
                  icon: "error",
                  html: verificationResponse.data.message || "Payment verification failed. Please contact support.",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                });
              }
            } catch (error) {
              setIsLoading(false);
              let errorMessage = "An unexpected error occurred. Please try again later.";
              let errorTitle = "<strong>Payment Verification Error</strong>";
            
              if (error.response) {
                switch (error.response.status) {
                  case 400:
                    errorTitle = "<strong>Invalid Payment</strong>";
                    errorMessage = "The payment couldn't be verified. Please try again or contact support.";
                    break;
                  case 404:
                    errorTitle = "<strong>Payment Not Found</strong>";
                    errorMessage = "The payment record couldn't be found. Please contact support.";
                    break;
                  case 500:
                    errorTitle = "<strong>Server Error</strong>";
                    errorMessage = "An error occurred on our servers. Please try again later or contact support.";
                    break;
                }
                errorMessage = error.response.data.message || errorMessage;
              }
            
              Swal.fire({
                title: errorTitle,
                icon: "error",
                html: errorMessage,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
              });
            }
          },
          modal: {
            ondismiss: async function() {
              console.log('Checkout form closed');
              try {
                // Make an API call to your backend to verify and store the payment
                const verificationResponse = await axios.post(
                  `${baseURL}/payment/paymentCancelled`,
                  {
                    orderId : orderId
                  }
                );
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment cancelled</strong>",
                  icon: "info",
                  html: "❌ Payment Cancelled",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                }).then(() => {
                  // Reload the website.
                  window.location.replace("/");
                });
              } catch (error) {
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment Cancelled</strong>",
                  icon: "info",
                  html: "❌ Payment cancelled",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                }).then(() => {
                  // Reload the website.
                  window.location.replace("/");
                });
              }   
            }
          }
        };
  
        const rzp = new window.Razorpay(options);
        
        rzp.on('payment.failed', function (response){
          // setIsLoading(true);
          // Swal.fire({
          //   title: "<strong>Payment Failed..!</strong>",
          //   icon: "error",
          //   html: response.error.description || "Payment failed. Please try again later.",
          //   showCloseButton: true,
          //   showCancelButton: true,
          //   focusConfirm: false,
          // });
          console.error(response.error);   
        });   
        rzp.open();
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "<strong>Error initiating the payment</strong>",
          icon: "error",
          html: "Failed to initiate the payment. Please try again later.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      }
    } catch (error) {
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      const text =
        '<span className="error-message">❌ Please try again.</span>';

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorHtml = '<ul >';
        let index = 1;
        for (let fieldName in errors) {
            errorHtml += `<li style="text-align: left;">${index}. ${errors[fieldName][0]}</li>`;
            index++;
        }
        errorHtml += '</ul>';
        Swal.fire({
            title: "<strong>Error initiating the payment</strong>",
            icon: "error",
            html: errorHtml,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      } else {
        Swal.fire({
            title: "<strong>Unexpected Error</strong>",
            icon: "error",
            html: "An unexpected error occurred. Please try again later.",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      }
    }
  };

  return (
    <section className="user-info" id="userinfo-section">
      {/* <div className="pattern-layer"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="align-title">
              {/* <h5>Provide us</h5> */}
              <h3>Register with us</h3>
            </div>
          </div>
          <div className="col-xl-12">
            {/* <form onSubmit={handleSubmit}> */}
            <form id="userInfoForm">
              <div className="row">
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name (as in Aadhar card)"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <SelectOption
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    options={genderOptions.map((gender) => ({
                      id: gender,
                      title: gender,
                    }))}
                    placeholder="Select Gender"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    options={formData.states.map((state) => ({
                      id: state.id,
                      title: state.state_title,
                    }))}
                    placeholder="Select State"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <SelectOption
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    options={formData.districts.map((district) => ({
                      id: district.id,
                      title: district.district_title,
                    }))}
                    placeholder="Select District"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="taluk"
                    value={formData.taluk}
                    onChange={handleChange}
                    options={formData.taluks.map((taluk) => ({
                      id: taluk.id,
                      title: taluk.name,
                    }))}
                    placeholder="Select Taluk"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="pin"
                    value={formData.pin}
                    onChange={handleChange}
                    placeholder="PIN"
                    autoComplete="OFF"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormField
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="educationLevel"
                    value={formData.educationLevel}
                    onChange={handleChange}
                    options={educationLevelOptions.map((educationLevels) => ({
                      id: educationLevels,
                      title: educationLevels,
                    }))}
                    placeholder="Select Education Level"
                    required
                  />
                </div>
                {formData.educationLevel && formData.educationLevel !== "Other" && (
                  <div className="col-md-6">
                    <SelectOption
                      name="branch"
                      value={formData.branch}
                      onChange={handleChange}
                      options={branchOptions[formData.educationLevel].map(
                        (branch) => ({
                          id: branch,
                          title: branch,
                        })
                      )}
                      placeholder="Select Branch (If Applicable)"
                    />
                  </div>
                )}
                {formData.educationLevel && formData.educationLevel !== "Other" && (
                  <div className="col-md-6">
                    <SelectOption
                      name="year"
                      value={formData.year}
                      onChange={handleChange}
                      options={yearOptions[formData.educationLevel].map(
                        (year) => ({
                          id: year,
                          title: year,
                        })
                      )}
                      placeholder="Select Year (If Applicable)"
                    />
                  </div>
                )}
                {formData.educationLevel == "Other" && (
                  <div className="col-md-6">
                    <FormField
                      type="text"
                      name="study"
                      value={formData.study}
                      onChange={handleChange}
                      placeholder="Enter what you are studying"
                      required
                    />
                  </div>
                )}
                {formData.educationLevel !== "10th" && (
                  <div className="col-md-6">
                    <FormField
                      type="text"
                      name="specialization"
                      value={formData.specialization}
                      onChange={handleChange}
                      placeholder="Specialization"
                      required
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={handleChange}
                    placeholder="School/College/University Name"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="schoolPlace"
                    value={formData.schoolPlace}
                    onChange={handleChange}
                    placeholder="School/College/University Place"
                    required
                  />
                </div>

                <div className="col-md-6">
                  <FormField
                    type="number"
                    name="schoolContact"
                    value={formData.schoolContact}
                    onChange={handleChange}
                    placeholder="School Contact Number"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="schoolCode"
                    value={formData.schoolCode}
                    onChange={handleChange}
                    placeholder="School/College/University Code"
                    // required
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <label className="terms-checkbox">
                    <input
                      type="checkbox"
                      name="termsAccepted"
                      checked={formData.termsAccepted}
                      onChange={handleCheckboxChange}
                      required
                    />
                    <span className="checkmark" />
                    By checking this box, you agree to our &nbsp;
                    <span className="checkbtn" onClick={termsHandler}>
                      Terms & Conditions
                    </span>   
                  </label>
                </div> 
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <span className="checkbtn" onClick={privacyHandler} style={{color: "red", cursor : "pointer"}}>
                    Privacy Policy
                  </span>               
                </div>
              </div>
              <div className="button-container">
                <button type="submit" className="btn-1">
                  Preview <span />
                </button>
              </div>
            </form>
            <Modal show={showPreview} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormDataPreview formData={formData} />
              </Modal.Body>
              <Modal.Footer>
                <ReCAPTCHA
                  sitekey={captchaSitekey}
                  onChange={handleCaptchaChange}
                  style={{ transform: "scale(0.8)" }} // Adjust the scale factor as needed
                />

                <div className="button-group">
                  <Button
                    variant="primary btn-sm"
                    onClick={handlePayNow}
                    // disabled={!captchaVerified} // Disable button if ReCAPTCHA is not verified
                  >
                    Pay Now
                  </Button>
                  <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal show={showterms} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TermsPreview />
                {/* <FormDataPreview formData={formData} /> */}
              </Modal.Body>
              <Modal.Footer>
                <div className="button-group">
                  <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                    I Agree
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal show={privacyPolicy} size="xl" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PrivacyPolicyPreview />
              </Modal.Body>
            </Modal>
            {/* <PaymentModal
              show={isPaymentModalOpen}
              onHide={() => setIsPaymentModalOpen(false)}
              onPayNow={performPaymentAction} // You can pass your payment action function here
            /> */}
            {isLoading && <Preloader />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserInfo;
