import React from "react";
import { Link } from "react-router-dom";
const Event = () => {
  return (
    <section
      className="home-three-event"
      id="events-section"
      style={{ backgroundColor: "#F9F4E8" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="align-title">
              <h5>Unique Patronage to Blossom</h5>
              <h3>
                PATRONS<span>@9</span>
              </h3>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="home-two-about-wrapper">
              <div className="about-right-container">
                <div className="common-title">
                  <h5>About</h5>
                </div>
                <div class="row">
                  <div className="col-lg-6 col-md-12">
                    <p className="p-justify" style={{ marginTop: "0px" }}>
                      Adhyayan Scholarship comes with <strong>PATRONS@9</strong>{" "}
                      to blossom 25 thousand children across the country by
                      keeping in mind a famous line{" "}
                      <strong>
                        "Together everybody's contribution, the unique patronage
                        to needy poor students"
                      </strong>
                      .
                    </p>
                    <p className="p-justify">
                      <strong>
                        <em>
                          Donate in units of Rs. 9 or multiples of one unit of
                          Rs. 9.
                        </em>
                      </strong>
                      The donation of a unit amount of Rs. 9 or its multiples
                      from all classes of people in society around the world
                      helps us reach our goal of blossoming 25,000 poor
                      children.
                    </p>
                    <p className="p-justify">
                      Every Parent ambition as "Raising a child is like planting
                      a seed and watching it grow into a beautiful flower." —
                      Lisa Wingate
                    </p>

                    <div className="patron faq-accordion accordion-right faq-page-accordion">
                      <style>
                        {`
                            @media only screen and (max-width: 740px) {
                              .faq-page-accordion .patron-button::after {
                                top: 21px;
                              }
                              .faq-page-accordion .patron-button:not(.collapsed)::after {
                                top: 21px;
                              }
                            }
                            .faq-accordion {
                              margin-top: 20px;
                            }
                            
                            .accordion-collapse p {
                              padding-left:  20px;
                              padding-right:  20px;
                            }
                            .patron-img img {
                              border-radius: 5px;
                            }
                            @media only screen and (max-width: 992px) {
                              .patron-img {
                                margin-top: 50px!important;
                              }
                            }
                        `}
                      </style>
                      <div className="accordion" id="accordionOne">
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="headingFive">
                            <button
                              className="patron-button accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              Read More
                            </button>
                          </h4>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionOne"
                          >
                            <div className="accordion-item">
                              <p
                                className="p-justify"
                                style={{ marginTop: "0px" }}
                              >
                                Donors can celebrate their every moment like
                                their children's, parents', and friends'
                                birthdays, special achievements and memories,
                                festivals, marriage anniversaries, etc., by
                                donating @Rs. 9 or multiples of it.
                              </p>
                              <p className="p-justify">
                                Once again, Adhyayan Scholarship
                                is appealing to all people of society and
                                companies to donate by registering online and
                                benefit from tax exemption under 80G, 12A
                                clauses. For companies, CSR certification of the
                                organization is also available.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="causes-right-content causes-right-wrapper causes-right-img patron-img">
                      <img src="assets/images/patron.png" alt="photo" />
                      <div class="text-center">
                        <Link to="/donate" target="_blank" rel="noopener noreferrer">
                          <button className="btn-1 btn-alt text-white" style={{ backgroundColor : 'rgb(111, 180, 238)'}}>Donate Now <span /></button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Event;
