import React from "react";

const Preloader = () => {
  return (
    <div className="loader-wrap">
      <div className="preloader">
        <div className="preloader-close">x</div>
        <div id="handle-preloader" className="handle-preloader home-1">
          <div className="loading">
            <span />
            <span />
            <span />
            <h2>Loading</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
