import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";
import Preloader from '../../components/Preloader';
import Header from '../../components/Header';
import MobileMenu from '../../components/MobileMenu';
// import Banner from '../../components/home/Banner';
import BannerTwo from '../../components/home/BannerTwo';
// import Services from '../../components/home/Services';
import Vision from '../../components/home/Vision';
// import About from '../../components/home/About';
import AboutTwo from '../../components/home/AboutTwo';
import Eligibility from '../../components/home/Eligibility';
import ModelQuestionPapers from '../../components/home/ModelQuestionPapers';
import Event from '../../components/home/Event';
import Gallery from '../../components/home/Gallery';
import Testimonial from '../../components/home/Testimonial';
import Sponsor from '../../components/home/Sponsor';
import Blog from '../../components/home/Blog';
import NewsLetter from '../../components/home/NewsLetter';
import Footer from '../../components/Footer';
import Scroller from '../../components/Scroller';
import Rewards from "../../components/home/Rewards";
import UserInfo from "../register/UserInfo";
import Swal from "sweetalert2";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    // throw new Error("Test error in Home component");
    //Disable Keyboard Shortcuts
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey && (event.key === 'c' || event.key === 'p')) ||
        event.key === 'PrintScreen'
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    //Disable Right-Click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);

    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");
    if (message) {
      const text =
        message === "SUCCESS"
        ? `<span className="success-message">
                🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
              </span>`
        : message === "ERROR"
        ? `<span className="error-message">
                ❌ Something went wrong. Please contact us for further information.
              </span>`
        : `<span className="error-message">
                ❌ Oops! Payment Failed. Please try again.
              </span>`;

      Swal.fire({
        title: "<strong>" + message + "</strong>",
        icon: message === "SUCCESS" ? "success" : "error",
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `
          <i class="fa fa-thumbs-up"></i> Great!
        `,
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonText: `
          <i class="fa fa-thumbs-down"></i>
        `,
        cancelButtonAriaLabel: "Thumbs down",
      }).then(() => {
        // Redirect to the register page
        window.location.replace("/");
      });
    }

    const script = document.createElement("script");
    script.src = "assets/js/script.js";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <>
      {/* <Preloader /> */}
      <Header/>
      <MobileMenu/>
      <BannerTwo />
      <Vision />     
      <AboutTwo />
      <Rewards/>
      <Eligibility />
      <ModelQuestionPapers />
      <Event />
      <Gallery />
      {/* <Testimonial /> */}
      <Sponsor />
      <UserInfo />
      {/* <Blog /> */}
      {/* <NewsLetter /> */}
      <Footer/>
      <Scroller />
    </>
  );
}

export default Home;
