import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Preloader from '../components/Preloader';
import '../pages/register/Form.css';

const RefPreview = () => {
  const [formData, setFormData] = useState({
    referrerName: '',
    referrerPlace: '',
    schoolCollegeName: '',
    address: '',
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const captchaSitekey = "6LfEcHQpAAAAANtrim9TrTzmuUkl8f44CStS_1E2";// works for the localhost, vibhaait.in and myadhyayan.in
  const baseURL = 'https://adhyayan-admin.vibhaait.in/public/api';
  // const baseURL = 'http://127.0.0.1:8000/api';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(value !== null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      Swal.fire({
        title: 'Error',
        text: 'Please complete the reCAPTCHA',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    // Trim the input values and check for empty fields
    let trimmedData = {};
    let localErrors = {};
    for (let key in formData) {
      trimmedData[key] = formData[key].trim();
      if (!trimmedData[key]) {
        localErrors[key] = 'This field is required and cannot be empty or just spaces.';
      }
    }
    console.log(localErrors);
    if (Object.keys(localErrors).length > 0) {
      setErrors(localErrors);
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseURL}/institute/referrer`,
        formData
      );
      if (response.status === 200 && response.data.success) {
        Swal.fire({
          title: 'Success',
          text: 'Referrer information submitted successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          setFormData({
            referrerName: '',
            referrerPlace: '',
            schoolCollegeName: '',
            address: '',
            email: '',
          });
          setErrors({}); // Clear errors on successful submission
          window.location.reload();
        });
        
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="ref-preview" id="ref-preview-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="referrerName">
                <Form.Control
                  type="text"
                  placeholder="Name of the referrer"
                  name="referrerName"
                  value={formData.referrerName}
                  onChange={handleChange}
                  required
                />
                {errors.referrerName && <div className="text-danger">{errors.referrerName}</div>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="referrerPlace">
                <Form.Control
                  type="text"
                  placeholder="Place of the referrer"
                  name="referrerPlace"
                  value={formData.referrerPlace}
                  onChange={handleChange}
                  required
                />
                {errors.referrerPlace && <div className="text-danger">{errors.referrerPlace}</div>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="schoolCollegeName">
                <Form.Control
                  type="text"
                  placeholder="School/College name"
                  name="schoolCollegeName"
                  value={formData.schoolCollegeName}
                  onChange={handleChange}
                  required
                />
                {errors.schoolCollegeName && <div className="text-danger">{errors.schoolCollegeName}</div>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="address">
                <Form.Control
                  as="textarea"
                  placeholder="Enter Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  style={{ height: '100px' }}
                  required
                />
                  {errors.address && <div className="text-danger">{errors.address}</div>}
              </Form.Group>
          

              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email Id of the referrer"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />        
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </Form.Group>

              <ReCAPTCHA
                sitekey={captchaSitekey}
                onChange={handleCaptchaChange}
              />

              <Button variant="success" type="submit" disabled={isLoading}>
                Submit
              </Button>
            </Form>
            {isLoading && <Preloader />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefPreview;
