import React, { useEffect } from "react";
import Header from "../../components/Header";
import MobileMenu from "../../components/MobileMenu";
import BannerTwo from "../../components/home/BannerTwo";
import UserInfo from "./UserInfo";
import Footer from "../../components/Footer";
import Scroller from "../../components/Scroller";
import useHandleLinkClick from '../../useHandleLinkClick';

const Register = () => {
  const handleLinkClick = useHandleLinkClick();
  useEffect(() => {
      const script = document.createElement("script");
      script.src = "assets/js/script.js";
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    return (
      <>
        <Header handleLinkClick={handleLinkClick}/>
        <MobileMenu handleLinkClick={handleLinkClick}/>
        <BannerTwo />
        <UserInfo />
        <Footer handleLinkClick={handleLinkClick}/>
        <Scroller />
      </>
    );
}

export default Register