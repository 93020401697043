import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import "jquery-validation";
import Swal from "sweetalert2";


import FormField from "../../components/register/FormField";
import SelectOption from "../../components/register/SelectOption";
import TextArea from "../../components/register/TextArea";

import { TermsPreview } from "../../pages/donation/TermsPreview.js";
// import { PrivacyPolicyPreview } from "../../pages/register/PrivacyPolicyPreview.js";
import FormDataPreview from "../../pages/register/FormDataPreview";
import "../../pages/register/Form.css";
import "../../pages/register/Modal.css";
import PaymentModal from "../../pages/payment/PaymentModal";
import Preloader from "../../components/Preloader";

const DonationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    mobileNumber: '',
    officeNumber: '',
    email: '',
    // donationReason: '',
    amount: '',
    customAmount: '',
    panCardNumber: '',
    donationLetter: null,
    // receiptPhoto: null,
    // otp: '',
    termsAccepted: false
  });
  const amountOptions = ["Rs. 9", "Other"];
  // const [otpRequired, setOtpRequired] = useState(false);
  const [amountInputVisible, setAmountInputVisible] = useState(false);
  

  const captchaSitekey = "6LfEcHQpAAAAANtrim9TrTzmuUkl8f44CStS_1E2";// works for the localhost, vibhaait.in and myadhyayan.in
  const [showPreview, setShowPreview] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  // const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showterms, setShowterms] = useState(false);
  // const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const location = useLocation();
  const baseURL = 'https://adhyayan-admin.vibhaait.in/public/api';
  // const baseURL = 'http://127.0.0.1:8000/api';
 
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");
    if (message) {
      const text =
        message === "SUCCESS"
        ? `<span className="success-message">
                🎉 Congratulations! Your donation has been successful! <br/>Please check your email for information.
              </span>`
        : message === "ERROR"
        ? `<span className="error-message">
                ❌ Something went wrong. Please contact us for further information.
              </span>`
        : `<span className="error-message">
                ❌ Oops! Payment Failed. Please try again.
              </span>`;

      Swal.fire({
        title: "<strong>" + message + "</strong>",
        icon: message === "SUCCESS" ? "success" : "error",
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: `
          <i class="fa fa-thumbs-up"></i> Great!
        `,
        confirmButtonAriaLabel: "Thumbs up, great!",
        // cancelButtonText: `
        //   <i class="fa fa-thumbs-down"></i>
        // `,
        // cancelButtonAriaLabel: "Thumbs down",
      }).then(() => {
        // Close the tab
        window.close();
      });
    }
    // Validate file extension custom method
    $.validator.addMethod("extension", function (value, element, param) {
      param = typeof param === "string" ? param.replace(/,/g, '|') : "pdf|PDF";
      return this.optional(element) || value.match(new RegExp(".(" + param + ")$", "i"));
    }, "");

    // Validate file size custom method
    $.validator.addMethod('filesize', function (value, element, param) {
      if (element.files && element.files.length > 0) {
        var size = element.files[0].size;
        size = size / 1024;
        size = Math.round(size);
        return this.optional(element) || size <= param;
      }
      return true;
    }, '');
    $("#donationForm").validate({
      rules: {
        name: "required",
        address: "required",
        mobileNumber: {
          required: true,
          minlength: 10,
          maxlength: 10,
          digits: true,
        },
        officeNumber: {
          // required: true,
          minlength: 10,
          maxlength: 10,
          digits: true,
        },
        email: {
          required: true,
          email: true,
        },
        // donationReason: "required",
        amount: "required",
        customAmount: {
          required: true,
          number: true,
          min: 1,
        },
        panCardNumber: {
          required: true,
          minlength: 10,
          maxlength: 10,
        },
        donationLetter: {
          required:true,
          extension: "pdf|PDF",
          filesize: 1024
        },
        termsAccepted: "required",  
      },

      messages: {
        name: "Please enter your name",
        address: "Please enter your address",
        mobileNumber: {
          required: "* Please enter your mobile number",
          minlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          digits: "* Please enter only digits",
        },
        officeNumber: {
          // required: "* Please enter office number",
          minlength: $.validator.format(
            "* Office number must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* Office number must be {0} digits long"
          ),
          digits: "* Please enter only digits",
        },
        email: {
          required: "Please enter your email address",
          email: "Please enter a valid email address",
        },
        amount: "Please select a donation amount",
        customAmount: {
          required: "Please enter a custom amount",
          number: "Please enter a valid amount",
          min: "Amount must be greater than 0",
        },
        panCardNumber: {
          required: "Please enter your PAN number",
          minlength: $.validator.format(
            "* PAN number must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* PAN number must be {0} digits long"
          ),
        },
        donationLetter: {
          required: "* File should not exceed 1 MB and it should be in pdf format.",
          extension: "* Please upload a PDF file",
          filesize: "* File size should not exceed 1 MB",
        },
        termsAccepted: "You must accept the terms and conditions",
      },
      errorPlacement: function (error, element) {
        if (element.attr("type") === "checkbox") {
          error.insertAfter(element.closest(".terms-checkbox"));
        } else {
          error.insertAfter(element);
        }
      },
      submitHandler: function (form, event) {
        event.preventDefault();
        // Your form submission logic here
        // For example, call handleSubmit() function
        setShowPreview(true);
      },
    });

    return () => {
      // Cleanup validation when component unmounts
      $('#donationForm').validate().destroy();
    };
    
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'file') {
      if (files && files.length > 0) {
        setFormData({ ...formData, [name]: files[0] });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      if (name === "amount") {
        return {
          ...prevData,
          [name]: value,
          customAmount: '',
          panCardNumber: '',
          donationLetter: null,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
    // if (name != 'Other') {
    //     setOtpRequired(false);
    // }
    setAmountInputVisible(value === 'Other');
  };
  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, customAmount: value });
    // setOtpRequired(Number(value) >= 99);
    if (value < 1999) {
      setFormData(prevFormData => ({
        ...prevFormData,
        panCardNumber: '',
        donationLetter: null
      }));
    }
  };
  const termsHandler = (e) => {
    setShowterms(true);
  };
  // const privacyHandler = () => {
  //   setPrivacyPolicy(true);
  // }

  const handleClose = () => {
    setShowPreview(false);
    setShowterms(false);
    setCaptchaVerified(false);
    // setPrivacyPolicy(false);
  };

  const handleCaptchaChange = (value) => {
    // Check if the ReCAPTCHA has been successfully completed
    setCaptchaVerified(value !== null);
  };

  const handlePayNow = () => {
    if (captchaVerified) {
      console.log("PAY NOW action triggered");
      setIsLoading(true);
      performPaymentActionLive();
    } else {
      alert("Please complete the ReCAPTCHA before proceeding.");
    }
  };
  
  const performPaymentActionLive = async () => {
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }
    
    if (formData.customAmount >= 1999) {
      const imagedata = document.querySelector('input[type="file"]').files[0];
      formDataToSend.append('donationLetter',imagedata);
    }

    // Log FormData content for debugging
    for (let pair of formDataToSend.entries()) {
      // console.log(pair[0]+ ': ' + pair[1]);
    }

    try {
      const response = await axios.post(
        `${baseURL}/donation/initiate`,
        formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
        },
      });

      setShowPreview(false);
      setShowterms(false);
      // setPrivacyPolicy(false);
      setCaptchaVerified(false);
      
      if (response.data.success) {
        const orderId = response.data.razorpayData.order_id;
        const options = {
          ...response.data.razorpayData,
          handler: async function (paymentResponse) {
            console.log(paymentResponse);
            setIsLoading(true);
            try {
              // Make an API call to your backend to verify and store the payment
              const verificationResponse = await axios.post(
                `${baseURL}/donation/verify`,
                paymentResponse
              );
              
              if (verificationResponse.data.success) {
                setIsLoading(false);
                const text = `<span className="success-message">
                            🎉 Congratulations! Your donation has been successful! <br/>Please check your email for confirmation.
                            </span>`;
                Swal.fire({
                  title: "<strong>SUCCESS</strong>",
                  icon: "success",
                  html: `${text}`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: `
                    <i class="fa fa-thumbs-up"></i> Great!
                  `,
                  confirmButtonAriaLabel: "Thumbs up, great!",
                  // cancelButtonText: `
                  //   <i class="fa fa-thumbs-down"></i>
                  // `,
                  // cancelButtonAriaLabel: "Thumbs down",
                }).then(() => {
                  // Close the tab
                  window.close();
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment Verification Failed</strong>",
                  icon: "error",
                  html: verificationResponse.data.message || "Payment verification failed. Please contact support.",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                });
              }
            } catch (error) {
              setIsLoading(false);
              let errorMessage = "An unexpected error occurred. Please try again later.";
              let errorTitle = "<strong>Payment Verification Error</strong>";
            
              if (error.response) {
                switch (error.response.status) {
                  case 400:
                    errorTitle = "<strong>Invalid Payment</strong>";
                    errorMessage = "The payment couldn't be verified. Please try again or contact support.";
                    break;
                  case 404:
                    errorTitle = "<strong>Payment Not Found</strong>";
                    errorMessage = "The payment record couldn't be found. Please contact support.";
                    break;
                  case 500:
                    errorTitle = "<strong>Server Error</strong>";
                    errorMessage = "An error occurred on our servers. Please try again later or contact support.";
                    break;
                }
                errorMessage = error.response.data.message || errorMessage;
              }
            
              Swal.fire({
                title: errorTitle,
                icon: "error",
                html: errorMessage,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
              });
            }
          },
          modal: {
            ondismiss: async function() {
              console.log('Checkout form closed');
              try {
                // Make an API call to your backend to verify and store the payment
                const verificationResponse = await axios.post(
                  `${baseURL}/donation/paymentCancelled`,
                  {
                    orderId : orderId
                  }
                );
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment cancelled</strong>",
                  icon: "info",
                  html: "❌ Payment Cancelled",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                }).then(() => {
                  // CLose the tab
                  window.close();
                });
              } catch (error) {
                setIsLoading(false);
                Swal.fire({
                  title: "<strong>Payment Cancelled</strong>",
                  icon: "info",
                  html: "❌ Payment cancelled",
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                }).then(() => {
                  // CLose the tab
                  window.close();
                });
              }   
            }
          }
        };
  
        const rzp = new window.Razorpay(options);
        
        rzp.on('payment.failed', function (response){
          // setIsLoading(true);
          // Swal.fire({
          //   title: "<strong>Payment Failed..!</strong>",
          //   icon: "error",
          //   html: response.error.description || "Payment failed. Please try again later.",
          //   showCloseButton: true,
          //   showCancelButton: true,
          //   focusConfirm: false,
          // });
          console.error(response.error);   
        });   
        rzp.open();
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "<strong>Error initiating the payment</strong>",
          icon: "error",
          html: "Failed to initiate the payment. Please try again later.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      }
    } catch (error) {
      setShowPreview(false);
      setShowterms(false);
      // setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      const text =
        '<span className="error-message">❌ Please try again.</span>';

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorHtml = '<ul >';
        let index = 1;
        for (let fieldName in errors) {
            errorHtml += `<li style="text-align: left;">${index}. ${errors[fieldName][0]}</li>`;
            index++;
        }
        errorHtml += '</ul>';
        Swal.fire({
            title: "<strong>Error initiating the payment</strong>",
            icon: "error",
            html: errorHtml,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      } else {
        Swal.fire({
            title: "<strong>Unexpected Error</strong>",
            icon: "error",
            html: "An unexpected error occurred. Please try again later.",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
        });
      }
    }
  };

  return (
    <section className="mission user-info" id="userinfo-section" >
      {/* <div className="pattern-layer"></div> */}
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <div className="align-title">
                        <h3>Donate Now</h3>
                    </div>
                </div>
                <div className="col-xl-12"></div>
                  <form id="donationForm">
                    <div className="row">
                        <div className="col-md-6">
                            <label class="form-label text-dark">Name (as in Aadhar card) <span className="required">*</span></label>
                            <FormField
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange} 
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label class="form-label text-dark">Address <span className="required">*</span></label>
                            <TextArea
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label class="form-label text-dark">Mobile number <span className="required">*</span></label>
                            <FormField
                                type="text"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleInputChange} 
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label class="form-label text-dark">Office number (for charity organization/companies)</label>
                            <FormField
                                name="officeNumber"
                                value={formData.officeNumber}
                                onChange={handleInputChange} 
                                // required 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label class="form-label text-dark">Email address <span className="required">*</span></label>
                            <FormField
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange} 
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label class="form-label text-dark">Donation amount <span className="required">*</span></label>
                            <SelectOption
                                name="amount"
                                value={formData.amount}
                                onChange={handleAmountChange} 
                                options={amountOptions.map((amount) => ({
                                    id: amount,
                                    title: amount,
                                  }))}
                                placeholder="Select Amount"
                                required
                            />
                        </div>
                        {amountInputVisible && (
                            <div className="col-md-6">
                                <label class="form-label text-dark">Custom amount <span className="required">*</span></label>
                                <FormField
                                    type="number"
                                    name="customAmount"
                                    value={formData.customAmount}
                                    onChange={handleCustomAmountChange} 
                                    required
                                />
                            </div>
                        )}

                    {formData.customAmount >= 1999 && (
                        <>
                            <div className="col-md-6">
                                <label class="form-label text-dark">PAN Number <span className="required">*</span></label>
                                <FormField
                                    type="text"
                                    name="panCardNumber"
                                    value={formData.panCardNumber}
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </div>
                            <div className="col-md-12">
                                <label class="form-label text-dark">Donation giving letter from charity organization/companies <span className="required">*</span></label>
                                <FormField
                                    type="file"
                                    name="donationLetter"
                                    value={formData.donationLetter}
                                    // onChange={handleInputChange} 
                                    required 
                                />
                                {/* <input type="file" name="donationLetter" value={formData.donationLetter} /> */}
                            </div>
                        </>
                    )}  
                        {/* <div className="col-md-6">
                            <label class="form-label text-dark">If donation amount cash enter amount</label>
                            <FormField
                                type="number"
                                name="cashAmount"
                                value={formData.cashAmount}
                                onChange={handleInputChange} 
                                required
                            />
                        </div> */}
                        {/* <div className="col-md-6">
                            <label class="form-label text-dark">Photo of receipt given</label>
                            <FormField
                                type="file"
                                name="receiptPhoto"
                                value={formData.receiptPhoto}
                                onChange={handleInputChange} 
                                // required
                            />
                        </div> */}
                    {/* {otpRequired && (
                        <div className="col-md-6">
                            <label class="form-label text-dark">Check email and enter OTP</label>
                            <FormField
                                type="text"
                                name="otp"
                                value={formData.otp}
                                onChange={handleInputChange} 
                                required
                            />
                        </div>
                    )} */}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <label className="terms-checkbox">
                                <input
                                type="checkbox"
                                name="termsAccepted"
                                checked={formData.termsAccepted}
                                onChange={handleInputChange}
                                required
                                />
                                <span className="checkmark" />
                                Tick to accept &nbsp;
                                <span className="checkbtn" onClick={termsHandler}>
                                 Terms & Conditions
                                </span>
                            </label>
                        </div>
                        {/* <div className="col-lg-2 col-md-2 col-sm-12">
                            <span className="checkbtn" onClick={privacyHandler} style={{color: "red", cursor : "pointer"}}>
                                Privacy Policy
                            </span>               
                        </div> */}
                    </div>
                    <div className="button-container">
                        <button type="submit" className="btn-1">
                            Preview <span />
                        </button>
                    </div>
                </form>
                <Modal show={showPreview} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormDataPreview formData={formData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <ReCAPTCHA
                        sitekey={captchaSitekey}
                        onChange={handleCaptchaChange}
                        style={{ transform: "scale(0.8)" }} // Adjust the scale factor as needed
                        />

                        <div className="button-group">
                        <Button
                            variant="primary btn-sm"
                            onClick={handlePayNow}
                            // disabled={!captchaVerified} // Disable button if ReCAPTCHA is not verified
                        >
                            Pay Now
                        </Button>
                        <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                            Close
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={showterms} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TermsPreview />
                        {/* <FormDataPreview formData={formData} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="button-group">
                        <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                            I Agree
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                
                {isLoading && <Preloader />}
            </div>
        </div>
    </section>
  );
};

export default DonationForm;
