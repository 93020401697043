import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import useHandleLinkClick from '../useHandleLinkClick';

const MobileMenu = () => {
  const handleLinkClick = useHandleLinkClick();  

  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn">
        <i className="fas fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <a href="index-2.html">
            <img src="assets/images/logo.jpg" alt="logo" />
          </a>
        </div>
        <div className="menu-outer">
          <div
            className="collapse navbar-collapse show clearfix"
            id="navbarSupportedContent"
          >
            <ul className="navigation clearfix">
              <li>
                <ScrollLink
                  to="home-section"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("home-section")}
                  >
                  Home
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="about-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("about-section")}
                  >
                  About
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="rewards-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("rewards-section")}
                >
                  Awards
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="eligibility-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("eligibility-section")}
                >
                  Eligibility
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="events-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("events-section")}
                >
                  Patron
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="gallery-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("gallery-section")}
                >
                  Gallery
                </ScrollLink>
              </li>
              <li>
                {/* <ScrollLink
                  to="userinfo-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("userinfo-section")}
                  className="btn-1 btn-alt"
                >
                  Register
                </ScrollLink> */}
                <Link to="/donate" target="_blank" rel="noopener noreferrer" className="btn-1 btn-alt" style={{ backgroundColor : 'rgb(111, 180, 238)'}}>
                  Donate Now 
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-info">
          <h4>Contact Info</h4>
          <ul>
            {/* <li>
              <a href="#">Garike trust (R)</a>
            </li>
            <li>
              <a href="#">2nd Cross Rd, near siyarams building,</a>
            </li>
            <li>
              <a href="#">Gandhinagar, Mandya,</a>
            </li>
            <li>
              <a href="#">Karnataka 571401</a>
            </li> */}
            <li>
              Garike trust (R)
            </li>
            <li>
              #11/189, Anchekeri bidi,
            </li>
            <li>
              Srirangapattana,
            </li>
            <li>
              Srirangapattana Taluk, 
            </li>
            <li>
              Mandya - 571438,
            </li>
            <li>
              Karnataka state, India,
            </li>
            <li>
              <i className="flaticon-phone-call" /> +91 8310632618
            </li>
          </ul>
        </div>
        <div className="social-links">
          <ul className="clearfix">
            <li>
              <a href="https://www.facebook.com/adhyayanscholar?mibextid=ZbWKwL"  target="_blank">
              <span className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/adhyayanscholars?igsh=MXUwenh6ZzJtMWs1OA==" target="_blank">
                <span className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a href="https://wa.me/message/PX4A2D2CMKDJG1"  target="_blank">
                <span className="fa fa-whatsapp" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;
