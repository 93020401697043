import React from "react";
// import { Link } from "react-router-dom";
const Eligibility = () => {
  return (
    <section className="home-three-causes" id="eligibility-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="common-title">
              <h5>Eligibility & Exam - Pattern, Dates, Results </h5>
              <h3>Your Gateway to Success</h3>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="home-three-carousel">
              <div className="testimonials-carousel causes-carousel owl-carousel owl-theme">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="causes-right-content home3-causes-left-content">
                      <div className="testimonial-content-text">
                        <div className="home-three-causes-btn">
                          <a href="#">Education</a>
                        </div>
                        <h4>Eligibility Criteria</h4>
                        <p>
                          👉<strong>Academic Enrollment:</strong>
                          <br />
                          Must be a student currently attending school, college,
                          institution, or university. Open to all academic
                          semesters/years, from 9th standard to Ph.D. programs.
                        </p>
                        <br />
                        <p>
                          👉<strong>Residency:</strong>
                          <br />
                          Must be an Indian resident.
                        </p>
                        <br />
                        <p>
                          👉<strong>Exclusions:</strong>
                          <br />
                          Students who have discontinued their studies or have
                          failed are not eligible.
                        </p>
                        <br />
                        <p>
                          👉<strong>Registration Requirements:</strong>
                          <br />
                          Must provide a valid phone number and email ID for all
                          communications and login purposes.
                        </p>
                        {/* <div className="testimonial-right-content-wrapper">
                          <div className="testimonial-right-wrapper-inner">
                            <img
                              src="assets/images/gallery/testimonial-image-07.png"
                              alt="image"
                            />
                            <div className="testimonial-info">
                              <h6>Annette Black</h6>
                              <span>Volunteers</span>
                            </div>
                          </div>
                          <div className="quait">
                            <div className="header-link-btn">
                              <Link to="/register" className="btn-1">
                                Register Now
                                <span />
                              </Link>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="causes-right-content causes-right-wrapper causes-right-img">
                      <img
                        src="assets/images/gallery/eligibility.jpg"
                        alt="photo"
                      />
                      <div className="causes-right-compaign">
                        eligibility criteria
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="causes-right-content">
                      <div className="testimonial-content-text">
                        <div className="home-three-causes-btn">
                          <a href="causes-details.html">Education</a>
                        </div>
                        <h4>Exam and Enrollment Pattern</h4>
                        <p>
                          <strong>Digital Enrollment:</strong> Convenient and
                          quick registration process online.
                        </p>
                        <br />

                        <div className="row">
                          <div className="col-lg-6">
                            <p>
                              👉Exam Duration: Student can take examination from
                              6am - 6pm for 1 hour by login on announced date in
                              website.
                            </p>
                            <br />
                            <p>👉Total Marks: 60</p>
                            <br />
                            <p>
                              👉Comprehensive Coverage:
                              <br />
                              <ul style={{ paddingLeft: "20px" }}>
                                <li>🔹General English</li>
                                <li>🔹General Mathematics</li>
                                <li>🔹General Science</li>
                                <li>🔹General Knowledge</li>
                                <li>🔹Tailored to your current study level</li>
                              </ul>
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p>
                              👉Fair and Simple exams:
                              <br />
                              <ul style={{ paddingLeft: "20px" }}>
                                <li>
                                  🔹60 questions with 4 answer choices each
                                </li>
                                <li>🔹No negative marking</li>
                                <li>🔹No cutoff marks</li>
                              </ul>
                            </p>
                            <p>
                              👉Exam Requirements:
                              <br />
                              <ul style={{ paddingLeft: "20px" }}>
                                <li>
                                  🔹Access via your computer, laptop, mobile, or
                                  tablet with an internet connection
                                </li>
                                <li>
                                  🔹10 minutes before the exam start time as
                                  announced on our website
                                </li>
                                <li>
                                  🔹Get ready to test your knowledge and excel!
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-30">
                    <div className="causes-right-content">
                      <div className="testimonial-content-text">
                        <div className="home-three-causes-btn">
                          <a href="causes-details.html">Education</a>
                        </div>
                        <h4>Exam Dates &amp; Results</h4>

                        <div className="row">
                          <div className="col-lg-12 mb-30">
                            <p>
                              <ul>
                                <li>
                                  🔹 <strong>8 December 2024: </strong>This is
                                  the last date to register for all students
                                  studying in different syllabus/year or
                                  semester schemes in 9th and 10th grade, ITI,
                                  Diploma, Para Medical, and D. Pharma.
                                </li>
                              </ul>
                            </p>
                            <br />
                          </div>
                          <div className="col-lg-12 mb-30">
                            <p>
                              <ul>
                                <li>
                                  🔹 <strong>15 December 2024: </strong>This is
                                  the last date to register for all students
                                  studying in different syllabus/year or
                                  semester schemes in PUC (I & II), Bachelor's
                                  Degree courses, and B. Sc Nursing.
                                </li>
                              </ul>
                            </p>
                            <br />
                          </div>
                          <div className="col-lg-12 mb-30">
                            <p>
                              <ul>
                                <li>
                                  🔹 <strong>22 December 2024:</strong> This is
                                  the last date to register for all students
                                  studying in different syllabus/year or
                                  semester schemes in B.E, MBA, Pharmacy,
                                  Biotechnology, Architecture, Bioinformatics,
                                  Master's Degree, M. Tech, Medical, and Ph. D
                                  fellowships.
                                </li>
                              </ul>
                            </p>
                            <br />
                          </div>
                          <div className="col-lg-12 mb-30">
                            <p>
                              <ul>
                                <li>
                                  🔹 <strong>1 January 2025:</strong> Results
                                  will be announced.
                                </li>
                              </ul>
                            </p>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Eligibility;
