import React from "react";

const Vision = () => {
  return (
    <section className="mission">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="common-title">
              <h5 style={{textAlign:"center"}}>Redesign Future Education with Scholarships</h5>
              {/* <h3>Redesign your future with scholarships</h3> */}
            </div>
            <div className="mission-container">
              <div className="mission-shape">
                <img src="assets/images/shape/mission-shape.png" alt="shape" />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="col-md-12">
                    <div
                      className="mission-contant wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="mission-icon">
                        <img
                          src="assets/images/icons/mission-icon-01.png"
                          alt="icon"
                          style={{ width: "70%", important: "true" }}
                        />
                      </div>
                      <div className="mossion-info" style={{ marginLeft: "5px"}}>
                        <h5>Mission</h5>
                        <p className="p-justify">
                          Adhyayan scholarship program is the indigenous program
                          helps every student residing every corner of our
                          country to redesign their future.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-0">
                    <div
                      className="mission-contant wow fadeInDown animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="mission-icon">
                        <img
                          src="assets/images/icons/mission-icon-02.png"
                          alt="icon"
                        />
                      </div>
                      <div className="mossion-info">
                        <h5>Vision</h5>
                        <p className="p-justify">
                          Adhyayan scholarship program taking care of the
                          student by providing scholarships on every year till
                          their education desire completes to reach their dream
                          of the life by validating their caliber on every year.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-md-12">
                    <div
                      className="event-3-left wow fadeInLeft animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInLeft",
                      }}
                    >
                      <div className="event-3-left-image">
                        <img
                          src="assets/images/redesign.jpg"
                          alt="photo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
