import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import "../register/Modal.css";

export const TermsPreview = () => {
  return (
    <ListGroup as="ol" numbered>
      <ListGroup.Item as="li" className="justify-text">
        This charity initiative/policy is as per my own or the organization's/company's charity policy, with a patronage amount of Rs. 9 or more.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        I/We are doing this charity to help 25,000 financially poor children attain maximum education.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        I/We are fully aware of the organization's charity work.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Once I/We have given the mentioned charity amount to this organization, it cannot be taken back.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        I/We fully agree with the organization and will fully cooperate with the donation starting from Rs. 9 or more.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        I/We will upload documents as per charity clauses for amounts of Rs. 1999 or more.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        The organization has full rights to make any changes, alterations, upgrades, withdrawals, or distributions of articles/amounts under this 
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        PATRON@9 portal without any prior communication to the donor.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        All judicial matters fall under the jurisdiction of Mandya only.
      </ListGroup.Item>
    </ListGroup>
  );
};


