import React from "react";

const Scroller = () => {
  return (
    <>
    <button className="scroll-top scroll-to-target" data-target="html">
      <span className="icon-42">
        <i className="fa fa-angle-double-right"></i>
      </span>
    </button>
    </> 
  );
};

export default Scroller;
