import React from 'react'

function Rewards() {
  return (
    <section className="services" id="rewards-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="align-title">
              <h5>Special Awards</h5>
              <h3>
              Celebrating Champions, Recognizing Achievements             
                  </h3>
              <div className="title-shape">
                <img src="assets/images/shape/service-title-shape.png" alt="map" />
              </div>
            </div>
          </div>
          <div className="service-content-wrapper">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="service-container wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms"> 
                  <div className="service-container-overlay" /> 
                  <div className="service-number">
                    <span>01</span>
                  </div>
                  <div className="service-container-inner">
                    <div className="service-image">
                      <img src="assets/images/icons/service-shape-01.png" alt="shape" />  
                    </div>
                    <div className="service-icon">
                    <img src="assets/images/icons/lap.png" alt="shape" />
                    </div>
                    <h5>Special gift worth 40K laptop</h5>
                    <p>Total 60 marks has to obtain</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="service-container wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms"> 
                  <div className="service-container-overlay" /> 
                  <div className="service-number">
                    <span>02</span>
                  </div>
                  <div className="service-container-inner">
                    <div className="service-image">
                      <img src="assets/images/icons/service-shape-02.png" alt="icon" />
                    </div>
                    <div className="service-icon">
                    <img src="assets/images/icons/tab.png" alt="icon" />
                    </div>
                    <h5>Special gift worth 24K Samsung tablet</h5>
                    <p>Total 59 marks has to obtain</p>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="service-container wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                  <div className="service-container-overlay" /> 
                  <div className="service-number">
                    <span>03</span>
                  </div>
                  <div className="service-container-inner">
                    <div className="service-image">
                      <img src="assets/images/icons/service-shape-03.png" alt="icon" />
                    </div>
                    <div className="service-icon">
                    <img src="assets/images/icons/mobile.png" alt="icon" />
                    </div>
                    <h5>Special gift worth 12K Samsung mobile</h5>
                    <p>Total 58 marks has to obtain</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="service-container wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                  <div className="service-container-overlay" /> 
                  <div className="service-number">
                    <span>04</span>
                  </div>
                  <div className="service-container-inner">
                    <div className="service-image">
                      <img src="assets/images/icons/service-shape-04.png" alt="icon" />
                    </div>
                    <div className="service-icon">
                    <img src="assets/images/icons/cash.png" alt="icon" />
                    </div>
                    <h5>Special cash reward of 5000 rupees</h5>
                    <p>District topper student getting this additional reward</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Rewards