import React from "react";

const Gallery = () => {
  return (
    <div className="home-threee-gallery" id="gallery-section">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-3 col-sm-12 collomn">
            <div
              className="gallery-content wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="team-content-wrapper">
                <div className="team-image">
                  <img
                    src="assets/images/gallery/scholarship-gallery-1.jpg"
                    alt="image"
                  />
                  <div className="team-icon">
                    <ul>
                      <li>
                        <a
                          href="assets/images/gallery/scholarship-gallery-1.jpg"
                          target="_blank"
                          className="team-icon-plus lightbox-image"
                          data-fancybox="gallery"
                        >
                          <i className="flaticon-add" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 collomn">
            <div
              className="gallery-content wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="team-content-wrapper">
                <div className="team-image">
                  <img
                    src="assets/images/gallery/scholarship-gallery-2.jpg"
                    alt="image"
                  />
                  <div className="team-icon">
                    <ul>
                      <li>
                        <a
                          href="assets/images/gallery/scholarship-gallery-2.jpg"
                          target="_blank"
                          className="team-icon-plus lightbox-image"
                          data-fancybox="gallery"
                        >
                          <i className="flaticon-add" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 collomn">
            <div
              className="gallery-content wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="team-content-wrapper">
                <div className="team-image">
                  <img
                    src="assets/images/gallery/scholarship-gallery-3.jpg"
                    alt="image"
                  />
                  <div className="team-icon">
                    <ul>
                      <li>
                        <a
                          href="assets/images/gallery/scholarship-gallery-3.jpg"
                          target="_blank"
                          className="team-icon-plus lightbox-image"
                          data-fancybox="gallery"
                        >
                          <i className="flaticon-add" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 collomn">
            <div
              className="gallery-content wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="team-content-wrapper">
                <div className="team-image">
                  <img
                    src="assets/images/gallery/scholarship-gallery-5.jpg"
                    alt="image"
                  />
                  <div className="team-icon">
                    <ul>
                      <li>
                        <a
                          href="assets/images/gallery/scholarship-gallery-5.jpg"
                          target="_blank"
                          className="team-icon-plus lightbox-image"
                          data-fancybox="gallery"
                        >
                          <i className="flaticon-add" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 collomn">
            <div
              className="gallery-content wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="team-content-wrapper">
                <div className="team-image">
                  <img
                    src="assets/images/gallery/scholarship-gallery-6.jpg"
                    alt="image"
                  />
                  <div className="team-icon">
                    <ul>
                      <li>
                        <a
                          href="assets/images/gallery/scholarship-gallery-6.jpg"
                          target="_blank"
                          className="team-icon-plus lightbox-image"
                          data-fancybox="gallery"
                        >
                          <i className="flaticon-add" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
