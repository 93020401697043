import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const FaqPreview = () => {
  return (
    <Accordion  defaultActiveKey="0" flush>
      <style>
        {`
          .accordion-button::before  {
            display: none!important;
          }
        `}
      </style>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Q1. Is Adhyayan scholarship program from a government or private organization?</Accordion.Header>
        <Accordion.Body>
        Adhyayan scholarship is offered by the Non-Governmental organization Garike Trust(R), based in Mandya, Karnataka State, India.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Q2. When did the Adhyayan scholarship program commence?</Accordion.Header>
        <Accordion.Body>
        The program began in 2018 with a written examination conducted offline for a small group of students.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Q3. How to make registration to Adhyayan scholarship examination?</Accordion.Header>
        <Accordion.Body>
        Visit website www.myadhyayan.in, in that go to registration details and fill all necessary details and get confirmation through given e-mail ID.									
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
        Q4. How scholarships distributing to students?
        </Accordion.Header>
        <Accordion.Body>
        Eligible students for scholarships intimating separately after verification from their study school/college/university, etc. Scholarship amount handovering to student through well organized function in district or state level.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
        Q5. How much is scholarship amount?
        </Accordion.Header>
        <Accordion.Body>
        Student can win scholarship from Rs.5000 to Rs.1 lakh as per their marks obtained out of 60 questions 
        with fatest answring time.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
        Q6. What is mode of communication to students on any updates about Adhyayan scholarships?
        </Accordion.Header>
        <Accordion.Body>
        All communications to given in web site only. Student have to be regular in touch into till scholarship distribution with their given e-mail ID and website
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
        Q7. what is the mode of Adayayan scholarship examination?
        </Accordion.Header>
        <Accordion.Body>
        The examination is conducted through online only.  Allowing students use their own mobile/computer/laptop/Tab to take the examination. There is NO paper or off line mode examination.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>Q8. What is the duration of the Adhyayan scholarship examination?</Accordion.Header>
        <Accordion.Body>
        The examination lasts for one (1) hour or 60 minutes.									
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>Q9. What is the question paper pattern?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Accordion.Header>
        <Accordion.Body>
        Each question in the paper has four (4) multiple-choice answers.						
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header>Q10. Which topics are covered in the Adhyayan scholarship examination?</Accordion.Header>
        <Accordion.Body>
        The examination covers topic from student current study level - General Knowledge(40 marks), General Mathematics(10 marks), General English(10 marks).
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="10">
        <Accordion.Header>Q11. Are the questions the same for all levels of students?</Accordion.Header>
        <Accordion.Body>
        No, each level of student receives different types of questions tailored to their educational level and knowledge.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="11">
        <Accordion.Header>Q12. How many attempts are provided in the Adhyayan scholarship examination?</Accordion.Header>
        <Accordion.Body>
        Only one attempt is allowed per enrolled student.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12">
        <Accordion.Header>Q13. Can students take a break during the Adhyayan scholarship examination?</Accordion.Header>
        <Accordion.Body>
        No breaks are allowed in the middle of the examination. If a student takes a break, considering how many questions answered up to the break taken.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="13">
        <Accordion.Header>Q14. Who is responsible for internet or system disruptions during the examination?</Accordion.Header>
        <Accordion.Body>
        Adhyayan scholarship promoters are not responsible for any disruptions, including internet or system failures, during the examination.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="14">
        <Accordion.Header>Q15. What is the examination fee and mode of payment?</Accordion.Header>
        <Accordion.Body>
        The examination fee is Rs. 99, payable through online only. Cash payments to any individuals are not accepted. If paid examination fee through cash, Adhyayan scholsrship promoters NOT responsible.        
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="15">
        <Accordion.Header>Q16. Which language is the medium of instruction for the examination?</Accordion.Header>
        <Accordion.Body>
        English is the only language used as the medium of instruction for all communications and in the question paper.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="16">
        <Accordion.Header>Q17. How many days does the Adhyayan scholarship examination run? </Accordion.Header>
        <Accordion.Body>
        The examination runs for 2-5 days through online only. Enrolled students can login on said date and time as per their current study level. Student have to login 10 minutes before to their examination time.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="17">
        <Accordion.Header>Q18. Are there negative marks in the Adhyayan scholarship examination? </Accordion.Header>
        <Accordion.Body>
        No, there are no negative marks.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="18">
        <Accordion.Header>Q19. Is there a cutoff for eligibility in the Adhyayan scholarship examination? </Accordion.Header>
        <Accordion.Body>
        No cutoff marks are required for eligibility.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="19">
        <Accordion.Header>Q20. Which students are eligible for the Adhyayan scholarship examination? </Accordion.Header>
        <Accordion.Body>
        PAN India students studying from 9th standard to Ph.D in any type of syllabus or course with semistar/year/other any type of schemes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="20">
        <Accordion.Header>Q21. Can failed students take the Adhyayan scholarship examination? </Accordion.Header>
        <Accordion.Body>
        No, failed/discontinued students are not eligible.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="21">
        <Accordion.Header>Q22. How can I ask my own questions or seek assistance? </Accordion.Header>
        <Accordion.Body>
        You can reach out through our WhatsApp link for responses within 5 to 60 minutes.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default FaqPreview