import React from 'react'
function SubscribePreview() {
  return (
    <>
    <h6>Please click on the icons below to join us!</h6>
    <div className='container mt-15'>
        <div className='row' style={{border:"1px solid #707070"}}>
            <div className='col'>
            <a target="_blank" href="https://www.instagram.com/adhyayanscholars?igsh=MXUwenh6ZzJtMWs1OA=="> <img src="assets/images/icons/instagram.jpeg" alt="insta"/></a>
            </div>
            <div className='col'>
            <a target="_blank" href="https://www.facebook.com/adhyayanscholar?mibextid=ZbWKwL"> <img src="assets/images/icons/facebook.jpeg" alt="facebook"/></a>
            </div>
            <div className='col'>
            <a target="_blank" href="https://wa.me/message/PX4A2D2CMKDJG1"> <img src="assets/images/icons/whatsapp.jpeg" alt="facebook"/></a>
            </div>
        </div>
    </div>
    </>
  )
}

export default SubscribePreview