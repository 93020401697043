import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Register from "./pages/register/Register.js";
import Donation from "./pages/donation/Donation.js";
import NoPage from "./pages/error/NoPage";
import ErrorBoundary from './ErrorBoundary';

// Global error handlers
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Global error caught:', message, source, lineno, colno, error);
  // Optionally display a user-friendly message or redirect to an error page
};

window.addEventListener('unhandledrejection', function(event) {
  console.error('Unhandled promise rejection:', event.reason);
  // Optionally display a user-friendly message or redirect to an error page
});

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/donate" element={<Donation />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
