import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import useHandleLinkClick from '../useHandleLinkClick';

const Nav = () => {
  const handleLinkClick = useHandleLinkClick();  

  return (
    <>
      <nav id="header" className="main-menu navbar-expand-md navbar-light">
        <div
          className="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            <li>
              <ScrollLink
                to="home-section"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("home-section")}
                >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="about-section"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("about-section")}
                >
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="rewards-section"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("rewards-section")}
              >
                Awards
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="eligibility-section"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("eligibility-section")}
              >
                Eligibility
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="events-section"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("events-section")}
              >
                Patron
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="gallery-section"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("gallery-section")}
              >
                Gallery
              </ScrollLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Nav;
