// TextArea.js
/* global $ */
import React from "react";
import "../../pages/register/Form.css";

const TextArea = ({
  name,
  value,
  onChange,
  placeholder,
  autoComplete,
  required,
  id
}) => {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      className={id && $(`#${id}`).hasClass("validation-error") ? "validation-error" : ""}
      placeholder={placeholder}
      autoComplete={autoComplete}
      required={required}
    />
  );
};

export default TextArea;
