import React from "react";
import "../../App.css";
export const ScrollingNotification = () => {
  return (
    <div class="d-flex justify-content-center bg-primary text-white ">
      <div className="notification-wrapper p-1" style={{ fontSize: "small" }}>
        Last date for Registration
      </div>
      <div
        className="notification-content text-warning p-1 blink_me"
        style={{ fontWeight: "700", fontSize: "small" }}
      >
        30th November 2024
      </div>
    </div>
  );
};

export default ScrollingNotification;
