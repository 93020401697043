import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import useHandleLinkClick from '../useHandleLinkClick';
const Header = () => {
  const handleLinkClick = useHandleLinkClick();

  return (
    <header id="home-section" className="main-header style-one home-three-header">
      {/* header-top */}
      <div className="header-lower">
        <div className="header_top">
          <div className="auto_container">
            <div className="header_top_left">
              <ul className="top_left">
                <li>
                  <a href="#">
                    <i className="flaticon-email" /> adhyayanscholars@gmail.com
                  </a>
                </li>
                <li style={{ textAlign : "center"}}>
                  <img src="assets/images/icons/location.png" alt="icon" />
                  Garike trust (R), #11/189, Anchekeri bidi, Srirangapattana, 
                  Srirangapattana Taluk, Mandya - 571438, Karnataka state, India
                </li>
              </ul>
            </div>
            <div className="header_top_right">
              <div className="header_right_info">
                <ul className="top_right">
                  <li>
                    <a href="https://www.facebook.com/adhyayanscholar?mibextid=ZbWKwL"  target="_blank">
                    <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/adhyayanscholars?igsh=MXUwenh6ZzJtMWs1OA==" target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/message/PX4A2D2CMKDJG1"  target="_blank">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header_bottom p_relative">
          <div className="auto_container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/">
                    <img src="assets/images/logo.jpg" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler">
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <Nav />
              </div>
              <div className="header__right home3__header__right">
                <div className="header__right__button">
                  <div className="header-link-btn">
                    {/* <ScrollLink
                      to="userinfo-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-70}
                      onClick={handleLinkClick("userinfo-section")}
                      className="btn-1 btn-alt"
                    >
                      Register
                    </ScrollLink> */}
                    <Link to="/donate" target="_blank" rel="noopener noreferrer">
                      <button className="btn-1 btn-alt text-white" style={{ backgroundColor : 'rgb(111, 180, 238)'}}>Donate Now <span /></button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*sticky Header*/}
      {/* <div className="sticky-header">
        <div className="auto_container">
          <div className="outer-box">
            <div className="logo-box">
              <figure className="logo">
                <Link to="/">
                  <img src="assets/images/logo.png" alt="" />
                </Link>
              </figure>
            </div>
            <div className="menu-area">
              <nav className="main-menu clearfix">
                Keep This Empty / Menu will come through Javascript
              </nav>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
};

export default Header;
