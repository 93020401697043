import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import "./Modal.css";

export const PrivacyPolicyPreview = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-25">
          <div className="home-three-causes-btn">
            <strong><span className="text-dark">Effective Date:</span></strong> <a href="#" style={{marginBottom: "5px"}}>28th June, 2024</a>
          </div>
          <p style={{ textAlign : "justify"}}>Garike Trust ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our examination portal (the "Service"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Service.</p>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">
              
              <h4>1. Information We Collect</h4>
              <p style={{ textAlign : "justify"}}>
                <strong>1.1 Personal Information : </strong>
                <br />
                We may collect personal information that you provide directly to us, including but not limited to:
                  <ul style={{ paddingLeft: "20px" }}>
                    <li>🔹Name</li>
                    <li>🔹Email address</li>
                    <li>🔹Phone number</li>
                    <li>🔹Postal address</li>
                    <li>🔹Donation information</li>
                    <li>🔹Identification details (e.g., Aadhaar number, PAN card number, or student ID)</li>
                    <li>🔹Education information (e.g., college/school information, course details)</li>
                </ul>
              </p>
              <br />
              <p style={{ textAlign : "justify"}}>
                <strong>1.2 Usage Data : </strong>
                <br />
                We may also collect information about how the Service is accessed and used. This Usage Data may include:
                <ul style={{ paddingLeft: "20px" }}>
                  <li>🔹Your computer's Internet Protocol (IP) address</li>
                  <li>🔹Browser type and version</li>
                  <li>🔹Pages of the Service that you visit</li>
                  <li>🔹The time and date of your visit</li>
                  <li>🔹The time spent on those pages</li>
                  <li>🔹Unique device identifiers</li>
                  <li>🔹Other diagnostic data</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">
              
              <h4>2. How We Use Your Information</h4>
              <p style={{ textAlign : "justify"}}>
                We use the information we collect in various ways, including to:
                  <ul style={{ paddingLeft: "20px" }}>
                    <li>🔹Provide, operate, and maintain our Service</li>
                    <li>🔹Improve, personalize, and expand our Service</li>
                    <li>🔹Understand and analyse how you use our Service</li>
                    <li>🔹Develop new products, services, features, and functionality</li>
                    <li>🔹Process your transactions and manage your orders</li>
                    <li>🔹Send you notifications, updates, and other administrative messages</li>
                    <li>🔹Communicate with you about our products, services, and promotions</li>
                    <li>🔹Monitor and analyse usage and trends to improve your experience with the Service</li>
                    <li>🔹Prevent fraudulent transactions, monitor against theft, and protect against criminal activity</li>
                    <li>🔹Comply with legal obligations and resolve any disputes we may have with you. All law disputes come under Mandya jurisdiction.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">    
              <h4>3. Sharing Your Information</h4>
              <p style={{ textAlign : "justify"}}>
              We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:
                <ul style={{ paddingLeft: "20px" }}>
                  <li>🔹<strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Service, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
                  <li>🔹<strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honour this Privacy Policy.</li>
                  <li>🔹<strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring company.</li>
                  <li>🔹<strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                </ul>
              </p>

            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">
              
              <h4>4. Security of Your Information</h4>
              <p style={{ textAlign : "justify"}}>
                We use administrative, technical, and physical security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We regularly review our security procedures to ensure the protection and integrity of your information.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">    
              <h4>5. Your Data Protection Rights</h4>
              <p style={{ textAlign : "justify"}}>
              Under the Indian data protection laws, you have the following rights regarding your personal information:
                <ul style={{ paddingLeft: "20px" }}>
                  <li>🔹<strong>Right to Access:</strong> You have the right to request access to the personal data we hold about you. This right is generally exercisable when you can demonstrate your identity and provide sufficient details to locate the information you seek. We may also verify your request to ensure it is not excessive or unfounded.</li>
                  <li>🔹<strong>Right to Rectification:</strong> You have the right to request correction of your personal data that is inaccurate or incomplete. This right can be exercised when you provide evidence or specific details about the inaccuracies needing correction.</li>
                  <li>🔹<strong>Right to Erasure:</strong> You have the right to request erasure of your personal data under certain circumstances, such as when the data is no longer necessary for the purposes for which it was collected, or if you withdraw your consent and there is no other legal ground for processing.</li>
                  <li>🔹<strong>Right to Restriction of Processing:</strong> You have the right to request restriction of processing of your personal data under certain circumstances. This right may apply, for example, if you contest the accuracy of the data or if the processing is unlawful, and you oppose erasure and request restriction instead.</li>
                  <li>🔹<strong>Right to Data Portability:</strong> You have the right to receive the personal data concerning you, which you have provided to us, in a structured, commonly used, and machine-readable format. This right applies when the processing is based on your consent or necessary for the performance of a contract, and the processing is carried out by automated means.</li>
                </ul><br/>
                To exercise these rights, please contact us using the contact information provided below.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="causes-right-content home3-causes-left-content">
            <div className="testimonial-content-text">       
              <h4>6. Contact Us</h4>
              <p style={{ textAlign : "justify"}}>
                If you have any questions about this Privacy Policy or wish to exercise your data protection rights under Indian law, please contact us at: 
                <a href="mailto:adhyayanscholars@gmail.com" style={{color:"#000"}}> adhyayanscholars@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
