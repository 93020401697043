import React from "react";

const Sponsor = () => {
  return (
    <div className="sponsor mt-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="align-title">
              <h3 style={{fontSize : "2rem"}}>
              Our Partners    
              </h3>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="brand-carousel owl-carousel owl-theme">
              <div className="sponsore-logo">
                <img
                  src="assets/images/brand/vibhaa.png"
                  alt="Vibhaa Technologies"
                  style={{ width: "100px" }}
                />
                <p style={{ fontSize: "13px" }}>Technology Partners</p>
                <p className="mt-5 text-center">
                  <a
                    href="https://vibhaa.in/"
                    target="_blank"
                    className="volunteers-info-link"
                    style={{
                      fontSize: "17px",
                    }}
                  >
                    Vibhaa Tech Innovations
                  </a>
                </p>
              </div>
              <div className="sponsore-logo">
                <img
                  src="assets/images/brand/an_idea_tech.png"
                  alt="An Idea Tech"
                  style={{ width: "100px" }}
                />
                <p style={{ fontSize: "13px" }}>Social Media Partners</p>
                <p className="mt-5 text-center">
                  <a
                    href="https://anideatech.com/"
                    target="_blank"
                    className="volunteers-info-link"
                    style={{
                      fontSize: "17px",
                    }}
                  >
                    An Idea Tech
                  </a>
                </p>
              </div>
              <div className="sponsore-logo">
                <img
                  src="assets/images/brand/career_gurukula.png"
                  alt="Career Gurukula"
                  style={{ width: "100px" }}
                />
                <p style={{ fontSize: "13px" }}>Career Alignment Partners</p>
                <p className="mt-5 text-center">
                  <a
                    href="https://careergurukula.in/"
                    target="_blank"
                    className="volunteers-info-link"
                    style={{
                      fontSize: "17px",
                    }}
                  >
                    Career Gurukula
                  </a>
                </p>
              </div>
              <div className="sponsore-logo">
                <img
                  src="assets/images/brand/suprapha_wellness.png"
                  alt="An Idea Tech"
                  style={{ width: "100px" }}
                />
                <p style={{ fontSize: "13px" }}>Mental Health Partners</p>
                <p
                  className="mt-5 text-center volunteers-info-link"
                  style={{
                    fontFamily: "Yeseva One",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "17px",
                  }}
                >
                  Suprabha Wellness
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;
