import React from "react";
import { useNavigate } from "react-router-dom";

const useHandleLinkClick = () => {
  const navigate = useNavigate();

  const handleLinkClick = (sectionId) => {
    return () => {
      // Redirect to the Home page
      navigate("/");
      // Scroll to the target section after the DOM is fully loaded
      document.addEventListener("DOMContentLoaded", () => {
        setTimeout(() => {
          const headerHeight = document.getElementById("header").offsetHeight; // Adjust this if your header has different height
          const targetElement = document.getElementById(sectionId);
          if (targetElement) {
            const offset = targetElement.offsetTop - headerHeight;
            window.scrollTo({
              top: offset,
              behavior: "smooth"
            });
          }
        }, 100);
      });
    };
  };

  return handleLinkClick;
};

export default useHandleLinkClick;
