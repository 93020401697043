import React from "react";
// import { Link } from "react-router-dom";
const ModelQuestionPapers = () => {
  return (
    <div className="blog events event-page">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
              <div className="align-title">
                  <h5>Checkout</h5>
                  <h3>Model Question Papers</h3>
              </div>
          </div>
          <div className="service-content-wrapper">
            <div className="row">
              <div className="col-xl-4 col-lg-4">
                <div
                  className="causes-card event-card wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div className="causes-image blog-image event-image">
                    <img src="assets/images/model-question-paper-1.jpg" alt="img" />
                  </div>
                  <div className="blog-contant event-content">  
                    <a href="#" className="hover-content">
                      Model Question Paper
                    </a>
                    <div className="comments">
                      <ul>
                        <li>
                          <a href="#" className="hover-content">
                            <i className="fa fa-solid fa-paperclip" /> <span> Set-1 </span>
                          </a>
                        </li>
                        <li>
                          <a href="assets/files/Model-Question-Paper-Set-1.pdf" className="hover-content" download>
                            <i className="fa fa-solid fa-download" /> <span> Download </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-btn event-btn opacity-btn"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div
                  className="causes-card event-card wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div className="causes-image blog-image event-image">
                    <img src="assets/images/model-question-paper-2.jpg" alt="img" />
                  </div>
                  <div className="blog-contant event-content">  
                    <a href="#" className="hover-content">
                      Model Question Paper
                    </a>
                    <div className="comments">
                      <ul>
                        <li>
                          <a href="#" className="hover-content">
                            <i className="fa fa-solid fa-paperclip" /> <span> Set-2 </span>
                          </a>
                        </li>
                        <li>
                          <a href="assets/files/Model-Question-Paper-Set-2.pdf" className="hover-content" download>
                            <i className="fa fa-solid fa-download" /> <span> Download </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-btn event-btn opacity-btn"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-lg-4">
                <div
                  className="causes-card event-card wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div className="causes-image blog-image event-image">
                    <img src="assets/images/model-question-paper-3.jpg" alt="img" />
                  </div>
                  <div className="blog-contant event-content">  
                    <a href="#" className="hover-content">
                      Model Question Paper
                    </a>
                    <div className="comments">
                      <ul>
                        <li>
                          <a href="#" className="hover-content">
                            <i className="fa fa-solid fa-paperclip" /> <span> Set-3 </span>
                          </a>
                        </li>
                        <li>
                          <a href="assets/files/Model-Question-Paper-Set-3.pdf" className="hover-content" download>
                            <i className="fa fa-solid fa-download" /> <span> Download </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-btn event-btn opacity-btn"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ModelQuestionPapers;
