// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  
  // export function register(config) {
  //   if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  //     // The URL constructor is available in all browsers that support SW.
  //     const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  //     if (publicUrl.origin !== window.location.origin) {
  //       // Our service worker won't work if PUBLIC_URL is on a different origin
  //       // from what our page is served on. This might happen if a CDN is used to
  //       // serve assets; see https://github.com/facebook/create-react-app/issues/2374
  //       return;
  //     }
  
  //     window.addEventListener('load', () => {
  //       const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
  //       if (isLocalhost) {
  //         // This is running on localhost. Let's check if a service worker still exists or not.
  //         checkValidServiceWorker(swUrl, config);
  
  //         // Add some additional logging to localhost, pointing developers to the
  //         // service worker/PWA documentation.
  //         navigator.serviceWorker.ready.then(() => {
  //           console.log(
  //             'This web app is being served cache-first by a service ' +
  //               'worker. To learn more, visit https://bit.ly/CRA-PWA'
  //           );
  //         });
  //       } else {
  //         // Is not localhost. Just register service worker
  //         registerValidSW(swUrl, config);
  //       }
  //     });
  //   }
  // }
  export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                checkValidServiceWorker(swUrl, config);
            } else {
                registerValidSW(swUrl, config);
            }
        });
    }
}

// Function to display an alert banner for installing the app
function showAlertBanner() {
    const alertBanner = document.createElement('div');
    alertBanner.innerHTML = `
        <p style="color:white">Install this application on your home screen for quick and easy access when you're on the go.</p>
        <button id="installButton" class="btn btn-info btn-1" style="padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;">Install App</button>
    `;
    alertBanner.style.position = 'fixed';
    alertBanner.style.top = '0';
    alertBanner.style.left = '0';
    alertBanner.style.width = '100%';
    alertBanner.style.backgroundColor = '#00715D';
    alertBanner.style.color = '#ffffff';
    alertBanner.style.padding = '10px';
    alertBanner.style.textAlign = 'center';
    alertBanner.style.zIndex = '1000'; // Ensure the banner appears above other content

    alertBanner.querySelector('#installButton').addEventListener('click', () => {
        // Prompt user to install the app (only supported on mobile devices)
        if (window.deferredPrompt) {
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    // Once installed, remove the alert banner
                    document.body.removeChild(alertBanner);
                    // Clear the deferred prompt variable
                    window.deferredPrompt = null;
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    });

    document.body.appendChild(alertBanner);

    // Automatically remove the alert banner after 5 seconds
    setTimeout(() => {
        document.body.removeChild(alertBanner);
    }, 5000);
}

// Check if the browser supports the beforeinstallprompt event
window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent the default browser prompt
    event.preventDefault();
    // Stash the event so it can be triggered later
    window.deferredPrompt = event;

    const lastPromptTime = localStorage.getItem('lastPromptTime');
    const currentTime = new Date().getTime();
    const hoursSinceLastPrompt = (currentTime - lastPromptTime) / (1000 * 60 * 60);

    // Show the alert banner only if it's been more than 24 hours since the last prompt
    if (!lastPromptTime || hoursSinceLastPrompt >= 24) {
        showAlertBanner();
        // Update the last prompt time in localStorage
        localStorage.setItem('lastPromptTime', currentTime);
    }
});

  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log(
                  'New content is available and will be used when all ' +
                    'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                );
  
                // Execute callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.');
  
                // Execute callback
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }
  